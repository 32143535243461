var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "popup__overlay",
        on: {
          click: function($event) {
            return _vm.closeSendReport()
          }
        }
      },
      [
        _c("div", { staticClass: "popup__box" }, [
          _c("img", {
            staticClass: "popup__img",
            attrs: { src: require("@/assets/report/SendReport.svg"), alt: "" }
          }),
          _c("div", { staticClass: "popup__title" }, [_vm._v("Thank you!")]),
          _c("div", { staticClass: "popup__subtitle" }, [
            _vm._v(_vm._s(_vm.text))
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }