<template>
  <!-- 送出表單 -->
  <transition name="fade">
    <div class="popup__overlay" @click="closeSendReport()">
      <div class="popup__box">
        <img class="popup__img" src="@/assets/report/SendReport.svg" alt />
        <div class="popup__title">Thank you!</div>
        <div class="popup__subtitle">{{ text }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    text: {
      default: "謝謝您的回報，我們將盡快為您優化",
      type: String,
    },
    direction: {
      default: "",
      type: String,
    },
  },
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.closeSendReport();
    }, 4000);
  },
  methods: {
    closeSendReport() {
      if (this.direction) {
        this.$router.push({ path: this.direction });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/popup.scss";
</style>
